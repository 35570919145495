import React from "react";
import logo from "./logo.svg";
import "./App.css";
import StatusPage from "./statusPage";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
    config: {
      initialColorMode: 'dark',
      useSystemColorMode: false,
    },
  });

function App() {
  return (
    <div className="App">
      <ChakraProvider theme={theme}>
        <StatusPage />
      </ChakraProvider>
    </div>
  );
}

export default App;
