import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, VStack } from "@chakra-ui/react";
import { setInterval } from "timers/promises";

interface Workflow {
  _id: string;
  status: string;
  job_id: string;
  updated_at: string;
}

function getLastUpdatedString(date: Date): string {
  const now = new Date();
  const diffInMs = now.getTime() - date.getTime();
  const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));

  return `${diffInHours} ${
    diffInHours === 1 ? "hour" : "hours"
  } ago`;
}
// dev account for workflow
const token = "cae4e0d96bf6-43f7-a726-e5ba9d29f54a:NTQ4NzM1ZGQtNmQyYy00YTM4LTg4NmQtMWY0Zjk4MmEwNmUx:1e3042:D8pDEayZJ8eWuh3ZDLpEk7KVPT72"
function MyTable() {
  const [jsonData, setJsonData] = useState<Workflow[]>([]);
  const fetchData = async () => {
    axios
      .get(
        `https://api-1e3042.stack.tryrelevance.com/latest/datasets/workflow-status/documents/list?page_size=1000`,
        {
          headers: { Authorization: token },
        }
      )
      .then((response: any) => {
        console.log(response);
        if (response) {
          setJsonData(response.data.documents);
          console.log(response.data.documents);
        }
      });
  };
  //   fetchData(); // call fetchData function immediately

  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       fetchData();
  //     }, 5000);
  //     return () => clearInterval(interval);
  //   }, []);
  // const POLL_INTERVAL = 2000;
  // useEffect(() => {
  //     fetchData(); // Fetch data immediately on component mount

  //     const pollIntervalId = setInterval(() => {
  //       fetchData(); // Set up polling
  //     }, POLL_INTERVAL);

  //     return () => {
  //       clearInterval(pollIntervalId); // Clean up interval on component unmount
  //     };
  //   }, []);

  return (
    <VStack>
      <Button onClick={fetchData}>Refresh</Button>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Status</th>
            <th>Last updated at</th>
            <th>Job ID</th>
          </tr>
        </thead>

        <tbody>
          {jsonData?.map((item: Workflow, index: number) => (
            <tr key={index}>
              <td>{item._id}</td>
              {item.status == "failed" && <td>❌</td>}
              {item.status == "complete" && "✅"}
              <td>{getLastUpdatedString(new Date(item.updated_at))}</td>
              <td>{item.job_id}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </VStack>
  );
}

function StatusPage() {
  return (
    <div>
      <h1>Workflow Status Page</h1>
      <MyTable />
    </div>
  );
}

export default StatusPage;
